import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from "@angular/core";
import { Observable, of, Subscription } from "rxjs";
import { LoadingComponent } from "./loading.component";

/**
 * Shows a loading spinner until the observable has emitted. Once it has, if the emitted
 * value is truthy show the content, otherwise optionally show an alternative template
 */
@Directive({
    selector: "[appAsyncIf]",
})
export class AsyncIfDirective<T> implements OnDestroy {
    private subscription?: Subscription;
    private isLoading = false;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("appAsyncIfElse") public elseTemplateRef?: TemplateRef<unknown>;

    public constructor(
        private templateRef: TemplateRef<{ $implicit: T }>,
        private viewContainer: ViewContainerRef,
    ) {}

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("appAsyncIfOf")
    public set loadingObservable$(value$: T | Observable<T>) {
        // If a second observable is set before the first
        this.subscription?.unsubscribe();

        if (!this.isLoading) {
            this.isLoading = true;
            this.viewContainer.clear();
            this.viewContainer.createComponent(LoadingComponent);
        }

        if (!(value$ instanceof Observable)) {
            value$ = of(value$);
        }

        // Use a sentinal value so a first emit of undefined doesn't early exit below
        // eslint-disable-next-line @typescript-eslint/ban-types
        let previousValue: T | Object = new Object();
        this.subscription = value$.subscribe((v) => {
            this.isLoading = false;

            if (previousValue === v) {
                return;
            }

            this.viewContainer.clear();

            if (v) {
                this.viewContainer.createEmbeddedView(this.templateRef, {
                    $implicit: v,
                });
            } else if (this.elseTemplateRef) {
                this.viewContainer.createEmbeddedView(this.elseTemplateRef);
            }

            previousValue = v;
        });
    }

    public ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    static ngTemplateContextGuard<T>(
        _dir: AsyncIfDirective<T>,
        ctx: unknown,
    ): ctx is { $implicit: Exclude<T, false | 0 | "" | null | undefined> } {
        return true;
    }
}

import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from "@angular/core";
import { Observable, of, Subscription } from "rxjs";
import { LoadingComponent } from "./loading.component";

/**
 * Shows a loading spinner before the observable has emitted or when it has emitted an undefined value
 */
@Directive({
    selector: "[appLoadingIfUndefined]",
})
export class LoadingIfUndefinedDirective<T> implements OnDestroy {
    private subscription?: Subscription;
    private isLoading = false;

    public constructor(
        private templateRef: TemplateRef<{ $implicit: T }>,
        private viewContainer: ViewContainerRef,
    ) {}

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("appLoadingIfUndefinedOf")
    public set loadingObservable$(value$: T | Observable<T>) {
        // If a second observable is set before the first
        this.subscription?.unsubscribe();

        if (!this.isLoading) {
            this.renderLoadingSpinner();
            this.isLoading = true;
        }

        if (!(value$ instanceof Observable)) {
            value$ = of(value$);
        }

        let previousValue: T;
        this.subscription = value$.subscribe((v) => {
            if (previousValue === v) {
                return;
            }

            previousValue = v;

            if (typeof v === "undefined") {
                if (!this.isLoading) {
                    this.renderLoadingSpinner();
                    this.isLoading = true;
                }

                return;
            }

            this.isLoading = false;
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef, {
                $implicit: v,
            });
        });
    }

    private renderLoadingSpinner() {
        this.viewContainer.clear();
        this.viewContainer.createComponent(LoadingComponent);
    }

    public ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    static ngTemplateContextGuard<T>(
        _dir: LoadingIfUndefinedDirective<T | undefined>,
        ctx: unknown,
    ): ctx is { $implicit: T } {
        return true;
    }
}
